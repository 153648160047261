import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql, Link, navigate } from "gatsby"

import { SEO } from "../components/Seo"
import { Header } from "../components/Header"
import { ButtonLabel } from "../elements/ButtonLabel"
import { IconArrow } from "../icons/IconArrow"

import {
  SITE__CONTACT,
  BUTTON__BACK,
  SITE__ABOUT_PAGE,
} from "../providers/texts"
import { FACEBOOK, EMAIL, WEBSITE, FORM } from "../providers/urls"

import styles from "./about.module.scss"

const About: React.FC = () => {
  const heroImage = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "0017/small/1.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <SEO />
      <div className={styles.container}>
        <Header resetState={() => navigate("/")} />

        <div className={styles.content}>
          <Img
            fluid={heroImage.file.childImageSharp.fluid}
            className={styles.image}
            alt={SITE__ABOUT_PAGE}
          />
          <div className={styles.text}>
            <div className={styles.description}>
              <div className={styles.description__paragraph}>
                Mapa veřejných prostorů zřízených za účelem obstarání malých
                dětí – přebalovny, kojicí koutky, krmicí místnosti a{"\xa0"}
                podobně. Najdete tu i{"\xa0"}
                bezbariérové veřejné toalety s{"\xa0"}přebalovacím pultem.
              </div>
              <div className={styles.description__paragraph}>
                V tuto chvíli mapa zobrazuje jen prostory, které jsou{" "}
                <span className={styles.description__emphasis}>
                  bezplatné či dostupné za symbolickou částku
                </span>,{" "}
                a zároveň je{" "}
                <span className={styles.description__emphasis}>
                  omezená na Prahu
                </span>
                . Je to proto, že tu autorka –{" "}
                <a
                  className={styles.description__link}
                  href={WEBSITE}
                  target="_blank"
                  rel="noreferrer"
                >
                  Bára
                </a>{" "}
                – bydlí a{"\xa0"}místní přebalovny má alespoň z{"\xa0"}části
                prochozené.
              </div>
              <div className={styles.description__paragraph}>
                Rozhodně ale uvítám tipy na další místa, nejen pražská! Chcete-li se podělit o
                svůj oblíbený kojicí koutek, budu ráda, když mi vyplníte{" "}
                <a
                  className={styles.description__link}
                  href={FORM}
                  target="_blank"
                  rel="noreferrer"
                >
                  formulář
                </a>
                , napíšete přes{" "}
                <a
                  className={styles.description__link}
                  href={FACEBOOK}
                  target="_blank"
                  rel="noreferrer"
                >
                  Facebook
                </a>{" "}
                nebo se ozvete na{" "}
                <a
                  className={styles.description__link}
                  href={EMAIL}
                  rel="noreferrer"
                >
                  {SITE__CONTACT}
                </a>
                . Budu se těšit a přebalovnám zdar!
              </div>
            </div>

            <div className={styles.button}>
              <Link to="/">
                <ButtonLabel ariaLabel={BUTTON__BACK}>
                  <>
                    <IconArrow />
                    <div className={styles.button__label}>{BUTTON__BACK}</div>
                  </>
                </ButtonLabel>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default About
